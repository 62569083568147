import '../styles/Cards.css';
import Card from "./Card";
import {useEffect, useState} from "react";
function Cards({ game, updateCardProgress, updateCardSelected }) {
    const [cards, setCards] = useState([]);
    useEffect(() => {
        setCards(game.cards);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div id="cards-wrapper">
            <div id="cards" className="cards cards-transition">
                {cards.map((card) => {
                   return (
                       <Card key={card.id} updateProgress={updateCardProgress} updateSelected={updateCardSelected} cardIndex={card.index} cardID={card.id} cardNumbers={card.numbers} cardProgress={card.progress} />
                     );
                })}
            </div>
        </div>
    );
}

export default Cards;
