import '../styles/CardsSummary.css';
import { useState } from "react";

function CardsSummary({ summary, resetGame }) {
    const [visible, setVisible] = useState(true);
    const handleClose = () => {
        setVisible(false);
        resetGame();
    };
    return (
        <dialog id="cards-summary" open={visible}>
            <div className="cards-summary-header">
                <h2>Game Results</h2>
                <button onClick={handleClose} className="close-btn"><i className="fa-solid fa-times"></i></button>
            </div>
            <div className="cards-results">
                <div className="winning">
                {summary.numbers.map((num) => {
                    return (
                        <span key={num.number} className={num.winning ? 'winning-number' : ''}>
                            {num.number}
                        </span>
                    )
                })}
                </div>
                <div className={`cards-result ${summary.cssClass}`}>
                    {summary.message}
                </div>

            </div>
        </dialog>
    );
}

export default CardsSummary;