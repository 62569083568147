import '../styles/CardsActions.css';
function CardsActions({ price, actionsVisible, handleProceed }) {
    return (
        <div className={actionsVisible ? 'cards-actions visible' : 'cards-actions'}>
            <div className="cards-price">
                <span className="currency">$</span>
                <span className="price">
                    {price.toFixed(2)}
                </span>
            </div>
            <button type="button" className="next-btn">
                <i className="fa-solid fa-arrow-right"></i>
            </button>
            <button onClick={handleProceed} type="button" className="button proceed-btn">
                Proceed
            </button>
        </div>
    );
}

export default CardsActions;