import '../styles/App.css';
import Header from './Header';
import Image from './Image';
import Cards from "./Cards";
import CardsActions from "./CardsActions";
import CardsSummary from "./CardsSummary";
import { createCardID, createNumberRange, createGameResults } from "../utils";
import { useState, useRef, useEffect } from 'react';
import CardSlider from "../classes/Slider";

function App() {
    const MAX_NUMBERS = 6;
    const PRICE_PER_CARD = 1.00;
    const MINIMUM_COMBINATION_LENGTH = 2;

    const [game, setGame] = useState({
        cards: []
    });
    const slider = useRef();
    const [cardSlider, setCardSlider] = useState(null);
    const [price, setPrice] = useState(0.00);
    const [actionsVisible, setActionsVisible] = useState(false);
    const [summary, setSummary] = useState(null);

    const resetGame = () => {
        setGame({
            cards: []
        });
        setPrice(0.00);
        setActionsVisible(false);
        setSummary(null);
        window.location = window.location.href;
    };

    const gameResults = () => {
        const results = createGameResults(MAX_NUMBERS);
        let count = [];
        const output = {
            message: 'You lost',
            cssClass: 'lost'
        };
        for(let i = 0; i < MAX_NUMBERS; i++) {
            const num = results[i];
            const cards = game.cards;
            cards.forEach((card) => {
                if(card.selected.includes(num)) {
                    count.push(num);
                }
            });
        }
        if(count.length >= MINIMUM_COMBINATION_LENGTH) {
            output.message = 'You won';
            output.cssClass = 'won';
        }
        output.numbers = results.map((num) => {
            return {
                number: num,
                winning: count.includes(num)
            };
        });
        return output;
    };

    const handleProceed = () => {
        const results = gameResults();
        setSummary(results);
    };

    const updatePrice = () => {
        setPrice(game.cards.length * PRICE_PER_CARD);
    };

    const updateActionsVisibility = () => {
        let selectedNumbers = 0;
        game.cards.forEach((card) => {
            selectedNumbers += card.selected.length;
        });
        if(selectedNumbers >= MAX_NUMBERS) {
            setActionsVisible(true);
        } else {
            setActionsVisible(false);
        }
    }

    const updateCardProgress = (cardID, num) => {
        const cards = game.cards;
        const cardIndex = cards.findIndex((card) => card.id === cardID);
        const card = cards[cardIndex];
        const progress = card.progress;
        if(card.selected.includes(num)) {
            card.progress = progress + 1;
        } else {
            card.progress = progress - 1;
        }
        setGame({cards});
        return card.progress;
    }

    const updateCardSelected = (cardID, num) => {
        const cards = game.cards;
        const cardIndex = cards.findIndex((card) => card.id === cardID);
        const card = cards[cardIndex];
        const selected = card.selected;
        const numIndex = selected.indexOf(num);
        if(selected.length === MAX_NUMBERS && !selected.includes(num)) {
            return false;
        }
        if(numIndex === -1) {
            selected.push(num);
        } else {
            selected.splice(numIndex, 1);
        }
        setGame({cards});
        updateActionsVisibility();
        return true;
    };

    const createCard = () => {
        const newCard = {
            id: createCardID(),
            numbers: createNumberRange(1, 90),
            progress: 0,
            selected: [],
            index: game.cards.length + 1
        };
        const cards = game.cards;
        cards.push(newCard);
        setGame({cards});
        cardSlider.init();
        cardSlider.setNumItems(game.cards.length);
        cardSlider.setCurrent(game.cards.length);
        if(game.cards.length > 1) {
            cardSlider.gotoNext();
        }
        updatePrice();

    };

    document.title = 'SuperEnalotto Online | React';

    useEffect(() => {
        setCardSlider(new CardSlider(slider.current));
    }, []);

    return (
        <>
            <main className="app">
                <Header title="SuperEnalotto Online" createCard={createCard} />
                <Image/>
                <div className="slider-wrapper" ref={slider}>
                    <Cards game={game} updateCardProgress={updateCardProgress} updateCardSelected={updateCardSelected} />
                </div>
                <CardsActions handleProceed={handleProceed} price={price} actionsVisible={actionsVisible}/>
            </main>
            {summary && <CardsSummary summary={summary} resetGame={resetGame}/> }
        </>
    );
}

export default App;
