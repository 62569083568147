import '../styles/Card.css';
import {useEffect, useState} from "react";

function Card({cardIndex, cardID, cardNumbers, cardProgress, updateProgress, updateSelected}) {
    const [progress, setProgress] = useState(cardProgress); // eslint-disable-line no-unused-vars
    const [width, setWidth] = useState(0); // eslint-disable-line no-unused-vars
    const [numbers, setNumbers] = useState([]); // eslint-disable-line no-unused-vars

    const handleSelection = (evt, num) => {
        if(updateSelected(cardID, num)) {
            evt.target.classList.toggle('selected');
            const value = updateProgress(cardID, num);
            setProgress(value);
            setWidth((value * 16.6666666667) + '%');
        }
    };

    useEffect(() => {
        setNumbers(cardNumbers);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="card-wrapper" data-position={cardIndex} style={{order: cardIndex}}>
            <header className="card-header">
                <strong>Choose 6 numbers</strong>
                <div className="card-progress">
                    <div style={{width: width}}></div>
                </div>
            </header>
            <div className="card">
                {numbers.map((number, index) => {
                    return (
                        <div onClick={(e) => handleSelection(e, number)} key={index} className="card-number">
                            {number}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Card;