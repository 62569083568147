import '../styles/Header.css';
function Header({ title, createCard }) {
    return (
        <header className="app-header">
            <h1>{title || 'SuperEnalotto Online'}</h1>
            <button className="add-card" onClick={createCard}>Play <i className="fa-solid fa-plus"></i></button>
        </header>
    );
}

export default Header;