function createRandomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}
export function createNumberRange(start, end) {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

export function createCardID(prefix = 'card-') {
    return `${prefix}${Math.random().toString(36).substr(2, 9)}`;
}

export function createGameResults(maxNumbers) {
    const results = [];
    for(let i = 0; i < maxNumbers; i++) {
        const num = createRandomNumberBetween(1, 90);
        if(!results.includes(num)) {
            results.push(num);
        } else {
            results.push(createRandomNumberBetween(1, 90));
        }
    }
    return results;
}