export default class CardSlider {
    constructor(elementReference) {
        this.element = elementReference;
        this.current = 1;
        this.numItems = 0;
    }

    init() {
        this.addEvents();
    }

    getNumItems() {
        return this.numItems;
    }

    setNumItems(numItems) {
        this.numItems = numItems;
    }

    setCurrent(current) {
        this.current = current;
    }

    addEvents() {
        const that = this;
        this.element.querySelector('#cards').addEventListener('transitionend', () => {
            that.changeOrder();
        }, false);
    }

    gotoNext() {
        this.element.querySelector('#cards').classList.add('cards-transition');
        this.element.querySelector('#cards').style.transform = 'translateX(-100%)';
        console.log(this.current, this.getNumItems());
    }

    changeOrder() {
        if(this.current === this.numItems) {
            this.current = 1;
        } else {
            this.current++;
        }
        let order = 1;
        for(let i = this.current; i <= this.numItems; i++) {
            this.element.querySelector(`.card-wrapper[data-position="${i}"]`).style.order = order;
            order++;
        }
        for(let i = 1; i < this.current; i++) {
            this.element.querySelector(`.card-wrapper[data-position="${i}"]`).style.order = order;
            order++;
        }
        this.element.querySelector('#cards').classList.remove('cards-transition');
        this.element.querySelector('#cards').style.transform = 'translateX(0)';
    }

}