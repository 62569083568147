import '../styles/Image.css';
import image from '../images/app-image.png';
function Image() {
    return (
        <figure className="app-image">
            <img src={image} alt="SuperEnalotto Online" />
        </figure>
    );
}

export default Image;